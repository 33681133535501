import makeECommerceRequest from 'src/core/http/e-commerce.client';
import getOrderQuery from './queries/get-order.query';
import getFulfillmentOrdersQuery from './queries/get-fulfillment-orders.query';
import { normalizeOrder } from '@/entities/account/features/order/helpers/normalization.helper';
import makeReturnRequestQuery from '@/entities/account/features/order/api/queries/make-return-request.query';

/**
 * @param {String} id:
 * @param {Object} options:
 * @param {Number} options.count:
 * @param {String} options.page:
 */
const getOrder = async (id, options) => {
  const response = await makeECommerceRequest(getOrderQuery, {
    id,
    ...options,
  });

  return response;
};

const getFulfillmentOrders = (payload) => {
  return makeECommerceRequest(getFulfillmentOrdersQuery, payload);
}

const getNormalizedOrder = async (id, options = {}) => {
  console.log('id', id);
  const normalizedId = `gid://shopify/Order/${id}`;

  const response = await getOrder(normalizedId, options);

  return normalizeOrder(response);
};

const requestReturn = async ({ orderId, returnLineItems }) => makeECommerceRequest(makeReturnRequestQuery, {
  input: {
    orderId,
    returnLineItems,
  },
});

export default {
  getOrder,
  getFulfillmentOrders,
  getNormalizedOrder,
  requestReturn,
};
