export default `
query getOrderFulfilments($id: ID!){
  returnableFulfillments(first: 8, orderId: $id) {
    edges {
      node {
        fulfillment {
          name
        }
        returnableFulfillmentLineItems(first: 9) {
          edges {
            node {
              quantity
              fulfillmentLineItem {
                id
                lineItem {
                  name
                  image {
                    url
                  }
                  variantTitle
                  product {
                    title
                    metafields(first: 5, keys: ["shoprz.returnable"]) {
                      edges {
                        node {
                          key
                          value
                        }
                      }
                    }
                  }
                  discountedUnitPriceSet {
                    shopMoney {
                      amount
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
