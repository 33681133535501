export default `query fulfillmentOrders($id: ID!) {
  order(id: $id) {
    id
    fulfillmentOrders(first: 5) {
      edges {
        node {
          id
          assignedLocation {
            location {
              id
              name
            }
          }
          lineItems(first: 20) {
            edges {
              node {
                id
                remainingQuantity
                lineItem {
                  id
                  name
                  variantTitle
                  originalUnitPriceSet {
                    shopMoney {
                      currencyCode
                      amount
                    }
                  }
                  originalTotalSet {
                    shopMoney {
                      currencyCode
                      amount
                    }
                  }
                  image {
                    url
                  }
                  product {
                    id
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`;