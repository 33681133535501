import makeECommerceRequest from 'src/core/http/e-commerce.client';
import getReturnableFulfillmentsQuery
  from '@/entities/account/features/request-return/api/queries/get-returnable-fulfillments.query';

const normalizeReturnableFulfillment = (response) => response.returnableFulfillments.edges.map(({ node: rf }) => ({
  fulfillment: {
    name: rf.fulfillment.name,
  },
  items: rf.returnableFulfillmentLineItems.edges.map(({ node: item }) => ({
    fulfillmentLineItem: {
      id: item.fulfillmentLineItem.id,
      lineItem: {
        ...item.fulfillmentLineItem.lineItem,
      },
      isReturnable: item.fulfillmentLineItem.lineItem.product.metafields?.edges?.[0]?.node?.value === 'true',
    },
    quantity: item.quantity,
  })),
}));

const getReturnableFulfillments = async (orderId) => {
  const normalizedId = `gid://shopify/Order/${orderId}`;
  const res = await makeECommerceRequest(getReturnableFulfillmentsQuery, {
    id: normalizedId,
  });

  return normalizeReturnableFulfillment(res);
};

export default {
  getReturnableFulfillments,
};
